import react, { useEffect, useState } from 'react'
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import BlueArrow from './../svgs/blue'
import BlueBg from './../svgs/blueBg'
import ReactHlsPlayer from 'react-hls-player';

const baseurl = process.env.REACT_APP_FRONT_LINK;

function DragAndDrop(props) {
    const history = useHistory();

    const [currentDrag, setCurrentDrag] = useState({});
    const [userSelect, setUserSelect] = useState(["", "", "", "", ""]);
    const [checkAnswers, setCheckAnswers] = useState(false);
    const [data, setData] = useState([]);
    const [items, setItems] = useState([
        // {
        //     id:"1",
        //     word:"I am Muzzy.",
        //     word2:"I'm Muzzy.",
        //     fileLink1:baseurl + "assets/tracks/t07/iam_muzzy.mp3",
        //     fileLink2:baseurl + "assets/tracks/t07/im_muzzy.mp3"
        // },
        // {
        //     id:"2",
        //     word:"I am Sylvia.",
        //     word2:"I'm Sylvia.",
        //     fileLink1:baseurl + "assets/tracks/t07/iam_selvia.mp3",
        //     fileLink2:baseurl + "assets/tracks/t07/im_selvia.mp3"
        // }

    ]);

    const [randList, setRandList] = useState([]);

    const [video, setVideo] = useState("");
    const [videoLink, setVideoLink] = useState("");



    const shuffle = (array) => {
        let currentIndex = array.length, randomIndex;

        // While there remain elements to shuffle...
        while (currentIndex != 0) {

            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }

        return array;
    }

    const changeTxt = (value, i) => {
        let data2 = [...data];
        data2[i].userAnswer = value;
        setData(data2);
    }

    const checkQuestion = () => {


        let answerFormat = {
            pass: true,
            progress: 0,
            questionTitle: props.questions.title,
            userAnswers: [],
            type: "look-listen-say"
        }

        let data2 = [...data];
        let allcorrect = true;
        data2.map((item) => {
            if (item.userAnswer.toLowerCase() === item.text.toLowerCase()) {
                item.correct = true;
            } else {
                item.correct = false;
                allcorrect = false;
            }
            answerFormat.userAnswers.push({
                userAnswer: item.userAnswer,
                correctAnswer: item.text,
                correct: item.correct
            })
            return item;
        })


        setData(data2);

        if (allcorrect === true) {
            document.getElementById("correct-au").play();
        } else {
            document.getElementById("wrong-au").play();
        }
        answerFormat.pass = allcorrect;

        //        setTimeout(() => {
        // history.push(baseurl + "circle_hear")
        // }, 3000);

        setTimeout(() => {
            props.nextExercise(answerFormat);
        }, 3000);
    }


    useEffect(() => {
        document.getElementById("intr").play();

        setItems(props.questions.answers)
        let currentItems = props.questions.answers;

        // currentItems = shuffle(currentItems);

        setTimeout(() => {
        }, 300)
        setData(props.questions.questions)
        setRandList(currentItems);


        if (props.questions.videoCode !== undefined) {

            fetch("https://api.vimeo.com/videos/" + props.questions.videoCode, {
                method: "GET",
                headers: {
                    "Authorization": "Bearer aeeec5987db81bbfd76e0f272e35468c",
                }
            }).then(response => response.json()).then(data => {
                // console.log(data);
                // setVideoData(data.embed.html)
                // console.log("video212",{
                //     autoplay: true,
                //     controls: true,
                //     responsive: true,
                //     fluid: true,
                //     sources: [{
                //       src: data.files[0].link,
                //       type: 'video/mp4'
                //     }]
                // })


                if (data.files !== undefined) {
                    let files = data.files;
                    let findHls = files.findIndex((item) => item.quality === "hls");

                    if (findHls !== -1) {
                        setVideoLink(data.files[findHls].link_secure);
                    }
                }

            });

        } else {
            setVideo(baseurl + props.questions.video);
        }

    }, [])

    const playIntr = (e) => {
        document.getElementById("intr").play();
    }

    const stopAllAudios = (i) => {
        let audios = document.querySelectorAll("audio");
        audios.forEach((audio) => {
            audio.pause();
            audio.currentTime = 0;
        })
    }


    const playAudio = (i) => {
        stopAllAudios();


        document.getElementById("filelink1_" + i).play();
        document.getElementById("filelink1_" + i).play();
    }

    const playAudio2 = (i) => {
        stopAllAudios();

        document.getElementById("filelink2_" + i).play();
        document.getElementById("filelink2_" + i).play();

    }

    return (<div>
        <div className="question-title-container">
            <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                <img src={baseurl + "assets/game5/write.svg"} />

                <h1 className="question-title" dangerouslySetInnerHTML={{ __html: props.questions.title }}></h1>
            </div>
            <div id="audio-bt" onClick={playIntr}><img src={baseurl + "assets/audio/listen.png"} /></div>
        </div>
        {/* <GreenCircle style={{marginLeft:-30,marginTop:-30}} /> */}


        {videoLink !== "" &&
            <div className="video-intro" style={{ height: "60vh", marginBottom: 30, width: "100%" }}>
                <ReactHlsPlayer
                    src={videoLink}
                    autoPlay={false}
                    controls={true}
                    width="100%"
                    height="auto"
                /></div>}

        {props.questions.topImage !== undefined && <div style={{
            borderRadius: 20,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            paddingBottom: "70px"
        }}><img style={{ width: "100%", height: "auto", borderRadius: 20 }} src={baseurl + props.questions.topImage} />

            {data.map((item, i) => {

                let border = { border: "3px dashed #0B329D", width: 54, height: 54 };

                if (item.correct === true) {
                    border.border = "3px dashed #45EA98";
                }

                if (item.correct === false) {
                    border.border = "3px dashed red";
                }


                if (item.width !== undefined) {
                    border.width = item.width;
                }
                if (item.height !== undefined) {
                    border.height = item.height;
                }


                return (<div

                    style={{

                        borderRadius: 20,
                        position: "absolute", background: "#C4EFFF", display: "flex", alignItems: "center", justifyContent: "center", left: item.left, right: item.right, top: item.top, bottom: item.bottom, ...border
                    }}>

                    <input type="text" maxLength={item.text.length} size={item.text.length} style={{
                        background: "none", border: "0",
                        color: "#0B329D",
                        textAlign: "center",
                        fontFamily: "gotham-medium",
                        fontSize: 30
                    }}
                        value={item.userAnswer}
                        onChange={(e) => {
                            changeTxt(e.target.value, i)
                        }}
                    />
                </div>)
            })}
        </div>}

        <div id="look_and_say" className="look_listen_say">
            {randList.map((item, j) => {

                item.word2 = item.word2.replaceAll(" ", "&nbsp;")
                item.word = item.word.replaceAll(" ", "&nbsp;")

                let background = {};
                let text_color = {};

                if (item.showArrow === true) {
                    background = { background: "#9AE5F8" }
                    text_color = { color: "#0b329d" }
                }
                return (
                    <div className="item-v1">
                        <div className="look_words" style={{ position: "static", justifyContent: "center", padding: 0, ...background }}>
                            <div className="word1" style={{ position: "relative" }} onClick={() => playAudio(j)}>
                                <div>

                                    {/* <BlueBg style={{width:"100%",height:"auto"}} /> */}
                                    <div style={item.showArrow === true ? { width: "420px", height: "75px" } : { width: "420px", height: "75px", background: "#9AE5F8" }}></div>
                                </div>
                                {/* <div style={{position:"absolute",left:0,top:0,width:"100%",textAlign:"center"}}>{item.word}</div> */}

                                <div style={{ position: "absolute", left: 0, top: 0, width: "100%", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }} dangerouslySetInnerHTML={{ __html: item.word }}></div>

                            </div>
                            {item.showArrow === true && <div style={{ display: "flex", alignContent: "center", alignItems: "center" }}><img src={baseurl + "assets/icons/arrow_blue1.png"} /></div>}
                            {item.word2 !== "" ? <div className="word2" style={{ position: "relative" }} onClick={() => playAudio2(j)}>
                                <div>

                                    {/* <BlueBg style={{width:"100%",height:"auto"}} /> */}

                                    <div style={item.showArrow === true ? { width: "420px", height: "75px" } : { width: "420px", height: "75px", background: "#22CBF2" }}></div>
                                </div>
                                <div style={{ position: "absolute", left: 0, top: 0, width: "100%", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", height: "100%", ...text_color }} dangerouslySetInnerHTML={{ __html: item.word2 }}></div>
                            </div> : <div></div>}

                            <audio id={"filelink1_" + j} src={baseurl + item.fileLink1} />
                            <audio id={"filelink2_" + j} src={baseurl + item.fileLink2} />


                        </div>

                    </div>)
            })}

            <audio src={`${baseurl}${props.questions.audio}`} className="hidden-audio" id="intr" />
            <audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
            <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />

        </div>

        {props.questions.table !== undefined &&
            <>
                <div className='table-style1'>
                    <table border='1' cellPadding="10" cellSpacing="0">
                        {props.questions.table.header !== undefined &&
                            <tr>
                                {props.questions.table.header.map((item) => {
                                    return (<th>{item}</th>);

                                })}
                            </tr>
                        }
                        {props.questions.table.data.map((item, i) => {
                            return (<tr>
                                {item.map((item2, j) => {
                                    return (<td>{item2}</td>)
                                })}

                            </tr>);
                        })}

                    </table>
                </div>
            </>
        }




        <div className="button-check">
            <button className="check-answer-bt" variant="contained" color="primary" onClick={checkQuestion}>Next</button>

        </div>


    </div>)
}
export default DragAndDrop;